import React from 'react';
import Cookies from 'universal-cookie';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const cookies = new Cookies();

const useGoogleAnalytics = (trackingId: string) => {
  const googleAnalyticsDisableCookieName = `ga-disable-${trackingId}`;

  const [isEnabled, setEnabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    const isEnabledAccordingToCookie = !!!cookies.get(
      googleAnalyticsDisableCookieName
    );
    setEnabled(isEnabledAccordingToCookie);
  }, []);

  React.useEffect(() => {
    if (isEnabled) {
      cookies.remove(googleAnalyticsDisableCookieName, {
        path: '/',
      });
      delete window[googleAnalyticsDisableCookieName];
    } else {
      cookies.set(googleAnalyticsDisableCookieName, true, {
        path: '/',
        expires: new Date(2099, 12, 31),
      });
      window[googleAnalyticsDisableCookieName] = true;
    }
  }, [isEnabled]);

  return {
    isEnabled,
    setEnabled,
    trackCustomEvent,
  };
};

export default useGoogleAnalytics;
