import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import useGoogleAnalytics from '../hooks/UseGoogleAnalytics';

const DataPrivacyPage = () => {
  const { isEnabled, setEnabled } = useGoogleAnalytics(
    process.env.GOOGLE_ANALYTICS_TRACKING_ID
  );

  return (
    <Layout>
      <SEO title="Data privacy statement | React Freelancer Switzerland" />
      <main>
        <div className="container text-left pt-5">
          <h1 className="text-dark mb-3">Data privacy statement</h1>
          <p>
            Florian Gyger Software takes the privacy of your data very
            seriously. Your personal data is treated trustworthy and aligned
            with the law and accordingly to this data privacy statement.
          </p>
          <p>
            By visiting this website you are giving consent to the collection
            and usage of information as declared in this data privacy statement.
            You also confirm to understand that this data privacy statement can
            be changed at any time and without prior announcement.
          </p>
          <p>
            Florian Gyger Software points out that there can be security
            vulnerabilities when transferring data over the internet (e.g. when
            communicating via email). A flawless protection of the data against
            the access by third parties is not entirely possible.
          </p>

          <h2>Cookies</h2>
          <p>
            The web sites partly use so called cookies. Cookies do not harm your
            device or contain any viruses. They are used to make this website
            more user-friendly and more effective. Basically, cookies are small
            text files that are saved to your device for this purpose.
          </p>
          <p>
            You can configure your browser in a way that you are able to decide
            whether to allow individual cookies or not, or to generally disallow
            saving cookies. However, by disallowing cookies the functionality of
            this website might be limited.
          </p>

          <h2>Server log files</h2>
          <p>
            The provider of this website collects and stores information that is
            automatically submitted by your browser in so called server log
            files. Those are:
          </p>
          <ul>
            <li>Browser type and version</li>
            <li>Used operation system</li>
            <li>Referrer URL</li>
            <li>Hostname of the accessing device</li>
            <li>Timestamp of the server request</li>
          </ul>
          <p>
            This information is not relatable to specific people. Also, the data
            is not merged together with information from other data sources.
            However, the data can be subsequently checked for specific evidence
            in cases of any illegal activity.
          </p>

          {/* <h2>Online calendar</h2>
          <p>
            The online calendar on this website uses the software of the service
            provider Calendly LLC, 1315 Peachtree St NE, Atlanta, GA 30309, USA.
            By using that service you accept the storage and management of your
            data as state in the{' '}
            <a
              href="https://calendly.com/de/pages/privacy"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              data privacy statement of Calendly
            </a>
            .
          </p> */}

          <h2>Mailing list</h2>
          <p>
            To be able to receive emails from the mailing list you have to
            submit your data first.
          </p>
          <p>
            The mailing list on this website uses the software of the service
            provider ActiveCampaign LLC, 1 North Dearborn Street, 5th floor,
            Chicago, IL 60602. By using that service you accept the storage and
            management of your data as state in the{' '}
            <a
              href="https://www.activecampaign.com/legal/privacy-policy"
              target="_blank"
              rel="nofollow noopener noreferrer"
              title="Data privacy statement of ActiveCampaign"
            >
              data privacy statement of ActiveCampaign
            </a>
            .
          </p>

          <h2>Google Analytics</h2>
          <p>
            This website uses the software Google Analytics of the service
            provider Google Inc., 1600 Amphitheatre Parkway Mountain View, CA
            94043, USA.
          </p>
          <p>
            Google Analytics uses so called cookies. These are small text files
            which are stored on your computer and used to analyze your usage of
            this website. The data generated by that cookie about your usage of
            this website is usually sent to a server from Google in the USA and
            stored there.
          </p>

          <h3>IP address anonymization</h3>
          <p>
            This website uses the anonymization functionality of Google
            Analytics. Your IP address is therefore shortened before trasmitting
            it to the USA. Only in exceptional cases the full IP address is
            transmitted to the servers in the USA and shortened there. As
            ordered by the owner of this site, Google will use that information
            to analyze your usage of this website, generate reports about the
            website activity and to provide other services related to the usage
            of the website and the internet to the owner of this website. The IP
            address submitted for this purpose is not merged together with other
            data of Google.
          </p>

          <h3>Refuse the data collection</h3>
          <p>
            You can refuse the collection by clicking the following link. An
            opt-out cookie will be set to stop the data collection when visiting
            this website in the future:{' '}
            {isEnabled ? (
              <a
                href="javascript:void(0)"
                onClick={() => setEnabled(false)}
                title="Disable Google Analytics on this website"
              >
                Disable Google Analytics
              </a>
            ) : (
              <a
                href="javascript:void(0)"
                onClick={() => setEnabled(true)}
                title="Enable Google Analytics on this website"
              >
                Enable Google Analytics
              </a>
            )}
          </p>
          <p>
            Learn more about how usage data is handled by Google Analytics in
            the{' '}
            <a
              href="https://support.google.com/analytics/answer/6004245?hl=en"
              target="_blank"
              rel="nofollow noopener noreferrer"
              title="Data privacy statement of Google"
            >
              data privacy statement of Google
            </a>
            .
          </p>

          <h3>Browser plugin</h3>
          <p>
            You can prevent your browser to save any cookies to your device by
            changing your browser configuration; by doing so you might no longer
            be able to use the full functionality of this website though. Also,
            you can prevent the collection and handling of the data about how
            you are using this website (including your IP address) by
            downloading and installing{' '}
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=en"
              target="_blank"
              rel="nofollow noopener noreferrer"
              title="Google Analytics Opt-out Browser Add-on"
            >
              this browser plugin
            </a>
            .
          </p>

          <h2>LinkedIn insight tag</h2>
          <p>
            This website uses the LinkedIn insight tag, which is a tracking and
            analyzation tool of LinkedIn Ireland Unlimited Company, Wilton
            Place, Dublin 2, Irland.
          </p>
          <p>
            The LinkedIn insight tag allows collecting data about the users of
            this website, including URL, referrer URL, IP address, device and
            browser properties (user agent), as well as timestamps.
          </p>
          <p>
            IP addresses are shortened or hashed. The direct relation to members
            is removed in seven days at most and replaced with an alias. Those
            aliased data is then deleted in 180 days maximum.
          </p>
          <p>
            LinkedIn does not share any personal data with the owner of this
            website but only shows summarized reports about the website target
            group and ad performance.
          </p>
          <p>
            The goal of the data collection is to analyze visits of our website
            and campaign results to offer interesting information to you.
          </p>
          <p>
            LinkedIn members are able to configure the usage of their personal
            data for advertisement purposes in the{' '}
            <a
              href="https://www.linkedin.com/psettings/advertising/actions-that-showed-interest"
              target="_blank"
              rel="nofollow noopener noreferrer"
              title="Your LinkedIn profile settings"
            >
              profile settings
            </a>
            .
          </p>
          <p>
            Find more information about LinkedIn's data privacy in the{' '}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="nofollow noopener noreferrer"
              title="Data privacy statement of LinkedIn"
            >
              data privacy statement of LinkedIn
            </a>
            .
          </p>

          <h2>SSL encryption</h2>
          <p>
            This site is using an SSL encryption to secure and protect
            confidentional data like for example requests you are sending to
            Florian Gyger Software. You can see that the connection is encrypted
            because the URL in your address field of the browser is changing
            from "http://" to "https://" and because a little lock symbol shows
            up next to it.
          </p>
          <p>
            No data you are transferring to the servers of this website can be
            read by third parties if SSL encryption is activated.
          </p>

          <h2>Your data rights</h2>
          <p>
            At any time you have the right to request a free report about how
            yuor personal data is stored, where it comes from, where it is sent
            to, as well as how it is used. You are also allowed to request to
            change, disable or delete your data. If you have questions about
            this or generally about personal data you can always{' '}
            <a
              href="mailto:info@floriangyger.ch"
              title="Write an email to Florian Gyger"
            >
              get in contact
            </a>
            .
          </p>

          <div className="overlay"></div>
        </div>
      </main>
    </Layout>
  );
};

export default DataPrivacyPage;
